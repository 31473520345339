<template>
	<div class="carousel">
		<div ref="siema" class="siema">
			<slot />
		</div>
		<template v-if="control && length > 1">
			<slot name="prev">
				<button class="arrow up" @click="prev()" v-if="vertical" />
				<button class="arrow left" @click="prev()" v-else />
			</slot>
			<slot name="next">
				<button class="arrow down" @click="next()" v-if="vertical" />
				<button class="arrow right" @click="next()" v-else />
			</slot>
		</template>
		<div v-if="indicator && length > 1" class="indicators" :class="`${indicator}`">
			<div class="row">
				<template v-for="i in length">
					<i class="indicator" :class="{ active: currentSlide == i - 1 }" @click="goTo(i - 1)" :key="i" />
				</template>
			</div>
		</div>
	</div>
</template>

<script>
import Siema from "siema";
export default {
	props: {
		indicator: [Boolean, String],
		control: Boolean,
		vertical: Boolean,
		options: {
			type: Object,
			default: () => new Object,
			validator: val => {
				const { interval, duration } = val;
				try {
					if (interval && (interval < 0 || interval < duration)) throw "interval can't be smaller than duration";
				} catch (e) {
					console.error("[carousel props]:", e);
					return false
				}
				return true;
			}
		}
	},
	data() {
		return {
			carousel: null,
			currentSlide: 0,
			length: 0,
		}
	},
	methods: {
		init() {
			const length = this.$slots.default && this.$slots.default.length || 0;
			if (length && length != this.length) {
				this.length = length;
				if (length > 1) {
					const vm = this;
					this.carousel = new Siema({
						...this.options,
						selector: this.$refs["siema"],
						onInit: function () {
							vm.onInit(this.currentSlide);
						},
						onChange: function () {
							vm.onChange(this.currentSlide);
						}
					});
				} else if (this.carousel) this.destroy();
			}
		},
		onInit(currentSlide) {
			this.handleAutoPlay();
			this.$emit("init", currentSlide);
		},
		onChange(currentSlide) {
			this.handleAutoPlay();
			this.currentSlide = currentSlide;
			this.$emit("change", currentSlide);
		},
		handleAutoPlay() {
			const { interval } = this.options;
			if (interval && interval > 0) {
				clearTimeout(this.timeout);
				this.timeout = setTimeout(() => {
					this.next();
				}, interval);
			}
		},
		goTo(index) {
			if (this.carousel) this.carousel.goTo(index);
		},
		prev(howManySlides = 1) {
			if (this.carousel) this.carousel.prev(howManySlides);
		},
		next(howManySlides = 1) {
			if (this.carousel) this.carousel.next(howManySlides);
		},
		remove(index) {
			if (this.carousel) this.carousel.remove(index);
		},
		insert(item) {
			if (this.carousel) this.carousel.insert(item);
		},
		prepend(item) {
			if (this.carousel) this.carousel.prepend(item);
		},
		append(item) {
			if (this.carousel) this.carousel.append(item);
		},
		destroy() {
			clearTimeout(this.timeout);
			if (this.carousel) this.carousel.destroy();
		}
	},
	mounted() {
		this.init();
	},
	updated() {
		this.init();
	},
	beforeDestroy() {
		this.destroy();
	}
}
</script>

<style lang="scss" scoped>
.carousel {
	position: relative;
}
.arrow {
	position: relative;
	width: 40px; // use px when browser not support rem
	width: 2.5rem;
	border-radius: 100%;
	padding: 0;
	transition: 0.5s;
	border: none;
	background: rgba(192, 169, 114, 0.3);
	cursor: pointer;
	outline: none;

	&::before {
		content: "";
		display: block;
		padding-top: 100%;
	}

	&::after {
		content: "";
		display: block;
		width: 25%;
		height: 25%;
		border-style: solid;
		border-width: 2px 2px 0 0;
		border-color: white;
		position: absolute;
		top: 50%;
		left: 50%;
	}

	&.right {
		&::after {
			transform: translate(-75%, -50%) rotate(45deg);
		}
	}

	&.left {
		&::after {
			transform: translate(-25%, -50%) rotate(-135deg);
		}
	}

	&.up {
		&::after {
			transform: translate(-50%, -25%) rotate(-45deg);
		}
	}

	&.down {
		&::after {
			transform: translate(-50%, -75%) rotate(135deg);
		}
	}

	&:hover {
		background: rgba(192, 169, 114, 0.6);
	}
}
.right {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	right: 10px; // use px when browser not support rem
	right: 0.625rem;
}
.left {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	left: 10px; // use px when browser not support rem
	left: 0.625rem;
}
.up {
	position: absolute;
	left: 50%;
	transform: translateX(-50%);
	top: 10px; // use px when browser not support rem
	top: 0.625rem;
}
.down {
	position: absolute;
	left: 50%;
	transform: translateX(-50%);
	bottom: 10px; // use px when browser not support rem
	bottom: 0.625rem;
}
.indicators {
	padding-top: 16px; // use px when browser not support rem
	padding-top: 1rem;

	&.inside {
		transform: translateX(-50%);
		position: absolute;
		bottom: 10px; // use px when browser not support rem
		bottom: 0.625rem;
		left: 50%;
	}

	.row {
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.indicator {
		width: 10px; // use px when browser not support rem
		height: 10px; // use px when browser not support rem
		width: 0.625rem;
		height: 0.625rem;
		cursor: pointer;
		transition: 0.3s;
		border-radius: 100%;
		background: $theme-light;
		&:not(:first-child) {
			margin-left: 10px; // use px when browser not support rem
			margin-left: 0.625rem;
		}

		&:hover,
		&.active {
			transform: scale(1.2);
			background: #325327;
		}
	}
}
</style>